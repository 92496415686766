<template>
  <v-container id="new-event-forms-view" tag="section">
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)">
        <v-card>
          <v-card-title>{{ $t("site.info") }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <validation-provider v-slot="{ errors }" name="codename" rules="required|unique" :debounce="200">
                  <v-autocomplete v-model="site.code_name" dense :items="codeNames" :search-input.sync="searchCodeNames"
                    auto-select-first autofocus outlined item-text="code_name" :label="$t('site.code.name')"
                    :error-messages="errors" hide-details="auto"></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field outlined dense v-model="site.contractor_id" :label="$t('site.contractor')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                  <v-text-field outlined dense v-model="site.guid" :label="$t('site.guid')" :error-messages="errors"
                    hide-details="auto" disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" name="name" rules="required" :debounce="200">
                  <v-text-field outlined dense id="name" :label="$t('site.name')" :error-messages="errors"
                    v-model="site.name" hide-details="auto"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field outlined dense v-model="site.password" :label="$t('site.password')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea outlined dense auto-grow rows="1" v-model="site.info" :label="$t('site.info')"
                  hide-details="auto"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea outlined dense auto-grow rows="1" v-model="site.notice" :label="$t('site.notice')"
                  hide-details="auto"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea outlined dense auto-grow rows="1" v-model="site.patrol_hint" :label="$t('site.patrol.hint')"
                  hide-details="auto"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <v-combobox v-model="chips" outlined dense flat chips :label="$t('site.tags')" multiple solo>
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip v-bind="attrs" :input-value="selected">
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <!-- <v-col cols="12" sm="8">
                <v-text-field
                  outlined
                  v-model="site.tags"
                  :label="$t('site.tags')"
                  hide-details="auto"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>{{ $t("site.address") }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field outlined dense v-model="site.city" :label="$t('site.city')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field outlined dense v-model="site.district" :label="$t('site.district')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="site.address" :label="$t('site.address')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field dense class="mb-2" outlined hide-details="true" v-model="address" ref="autocomplete"
                  id="autocomplete" v-on:keydown.enter.prevent="" prepend-inner-icon="mdi-map-marker"
                  :placeholder="$t('map.search')" />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field outlined dense v-model="coordinates" :label="$t('site.coordinates')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <gmaps-map :options="mapOptions" style="height: 800px" @mounted="ready" @click="changeMarker">
                <gmaps-marker v-if="marker" :options="marker" />
              </gmaps-map>
            </v-row>
          </v-card-text>
          <app-card-actions>
            <cancel-btn @click="$router.back()" />
            <apply-btn :disabled="!validate" type=submit />
            <destructive-btn :disabled="!validate" v-if="site.id && site.is_active" @click="block"
              :text="$t('btn.site.block')" />
            <destructive-btn :disabled="!validate" v-if="site.id && !site.is_active" @click="unblock"
              :text="$t('btn.site.unblock')" />
          </app-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
// import _ from "lodash"
import { IS_DEV } from '@/util/globals'
import { required } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider } from "vee-validate"

import { gmaps, gmapsMap, gmapsMarker } from "x5-gmaps"
import SiteService from "@/services/site.service"
import SiteMember from "@/store/modules/siteMember"
import { AccessLevel } from "@/store/modules/siteMember"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    gmapsMap,
    gmapsMarker
  },
  name: "NewSite",

  beforeMount() {
    const site = this.$route.params.site
    console.log('site :>> ', site);
    if (site) {
      this.site = site
      if (this.site.code_name) {
        this.codeNames = [this.site.code_name]
      }
    }
    if (this.$route.params.siteId) {
      if (!site) {
        SiteService.get(this.$route.params.siteId).then(response => {
          this.site = response.data
          this.codeNames = [this.site.code_name]
        })
      }
    }
  },
  mounted() {
    extend("required", {
      ...required
    })
    extend("unique", {
      validate: this.isCodenameUnique,
      message: this.$t("Codename already taken")
    })
  },
  beforeDestroy() { },
  computed: {
    validate() {
      return true
    },
    isEdit() {
      return this.site?.id
    },
    coordinates: {
      get() {
        if (this.site?.location?.coordinates) {
          return `${this.site.location.coordinates[1]} ${this.site.location.coordinates[0]}`
        } else {
          return null
        }
      },
      set(newValue) {
        const regExp = /^(\d+[.\d]+)([ ,:]+)(\d+[.\d]+)$/
        if (!regExp.test(newValue)) {
          return
        }
        const result = regExp.exec(newValue)
        if (result.length != 4) {
          return
        }
        var v1 = parseFloat(result[1])
        var v2 = parseFloat(result[3])
        this.marker = {
          id: "site",
          options: {},
          position: { lat: v1, lng: v2 }
        }
        this.mapOptions = {
          center: { lat: v1, lng: v2 }
        }
        return newValue
      }

    },
    title() {
      if (this.isEdit) {
        return this.site.name
      } else {
        return "Nowy obiekt"
      }
    }
  },
  data: () => ({
    // Search data
    chips: [],
    searchCodeNames: null,
    originalCodeName: [],
    codeNames: [],
    address: null,
    autocomplete: null,
    site: {
      site_type: 1,
    },
    map: null,
    marker: null,
    mapOptions: {
      zoom: 18,
      center: { lat: 0, lng: 0 }
    },
    lastPosition: null,
    globalMember: false,
    accessLevel: SiteMember.accessLevels(),
    selectedAccessLevel: AccessLevel.EMPLOYEE
  }),
  watch: {
    site: function () {
      if (this.site?.tags?.length) {
        this.chips = this.site.tags
          .split("#")
          .map(tag => tag.trim())
          .filter(tag => tag.length > 0)
      }
      this.originalCodeName = this.site.code_name
      if (this.site?.location == null) {
        return
      }
      this.mapOptions.center = {
        lng: this.site.location.coordinates[0],
        lat: this.site.location.coordinates[1]
      }
      this.marker = {
        id: "site",
        options: {},
        position: {
          lng: Number(this.site.location.coordinates[0]),
          lat: Number(this.site.location.coordinates[1])
        }
      }
      console.log("location :>> ", this.marker)
    },
    "site.address": function () {
      this.updateAddress()
    },
    "site.city": function () {
      this.updateAddress()
    },
    marker: function () {
      this.site.location = {
        type: "Point",
        coordinates: [this.marker.position.lng, this.marker.position.lat]
      }
      // localhost
      if (IS_DEV) {
        //distance from last position and current position
        if (this.lastPosition == null) {
          this.lastPosition = this.marker.position
          this.lastPosition.time = new Date()
          return
        }
        var distance = this.measure(this.lastPosition, this.marker.position)
        var miliseconds = Math.round(1000* distance / 1.4)
        this.lastPosition.time = new Date(this.lastPosition.time.getTime() + miliseconds) // 1.4 m/s - average speed of human
        this.lastPosition.lat = this.marker.position.lat
        this.lastPosition.lng = this.marker.position.lng


        this.copyToClipBoard(this.marker.position.lat + "," + this.marker.position.lng)
        console.log(`<wpt lat="${this.marker.position.lat}" lon="${this.marker.position.lng}">`)
        var timeIso = new Date(this.lastPosition.time).toISOString()
        // format time 2023-11-19T10:54:44Z
        timeIso = timeIso.substring(0, timeIso.length - 5) + "Z"
        console.log(`    <time>${timeIso}</time>`)
        console.log("</wpt>")
      }
    },
    searchCodeNames(value) {
      SiteService.getFreeCodenames(value).then(data => {
        this.codeNames = data
        if (this.originalCodeName && this.$route.params.siteId) {
          this.codeNames.push(this.originalCodeName)
        }
      })
    }
  },
  methods: {
    measure(from, to) {  // generally used geo measurement function
       var lat1 = from.lat
       var lon1 = from.lng
       var lat2 = to.lat
       var lon2 = to.lng
      var R = 6378.137; // Radius of earth in KM
      var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
      var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d * 1000; // meters
    },
    async isCodenameUnique() {
      if (!this.site.code_name) {
        return
      }
      let result = false
      await SiteService.fetchWithCodeName(this.site.code_name, true).then(
        response => {
          const data = response.data
          result =
            data.count == 0 ||
            (data.count == 1 && data.results[0].id == this.site.id)
        }
      )
      return result
    },
    siteSelected() { },
    async block() {
      const res = await this.$dialog.confirm({
        text: "Czy na pewno zablokować obiekt?",
        title: "",
        actions: {
          false: "Nie",
          true: {
            color: "error",
            text: "Zablokuj"
          }
        }
      })
      if (res === true) {
        this.site.is_active = false
        SiteService.createOrUpdateSite(this.site).then(() => {
          this.$router.back()
        })
      }
    },
    async unblock() {
      const res = await this.$dialog.confirm({
        text: "Czy na pewno odblokować object?",
        title: "",
        actions: {
          false: "Nie",
          true: {
            color: "error",
            text: "Tak"
          }
        }
      })
      if (res === true) {
        this.site.is_active = true
        SiteService.createOrUpdateSite(this.site).then(() => {
          this.$router.back()
        })
      }
    },
    ready(map) {
      this.map = map
      gmaps().then(maps => {
        this.autocomplete = new maps.places.Autocomplete(
          this.$refs.autocomplete.$refs.input
        )
        this.autocomplete.addListener("place_changed", this.update)
      })
    },
    updateAddress() {
      this.address = `${this.site.city}, ${this.site.address}`
    },
    changeMarker($event) {
      const position = $event.latLng.toJSON()
      this.marker = {
        id: "site",
        options: {},
        position: { lng: position.lng, lat: position.lat }
      }
    },
    search() {
      let element = this.$refs.autocomplete.$refs.input
      console.log("element :>> ", element)
    },
    update() {
      const place = this.autocomplete.getPlace()
      if (place.geometry) {
        const position = place.geometry.location
        this.marker = {
          id: "site",
          options: {},
          position: { lng: position.lng(), lat: position.lat() }
        }
        this.map.panTo(position)
      }
    },
    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    },
    submit() {
      this.site.tags = ""
      for (const tag of this.chips) {
        if (this.site.tags.length) {
          this.site.tags += " "
        }
        this.site.tags += "#" + tag
      }
      SiteService.createOrUpdateSite(this.site)
        .then(() => {
          this.$router.back()
        })
        .catch(error => {
          var message = error.response.data
          if (message.username) {
            message = message.username[0]
          }
          this.$dialog.notify.warning(message, {
            timeout: 5000
          })
        })
    }
  }
}
</script>
